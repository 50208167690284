import { AtSign, KeyRound, Mail } from "lucide-react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import CustomButton2 from "../../common/CustomButton2";
import CustomButton from "../../common/CustomButton";
import CustomLoader from "../../common/CustomLoader";
import Request, { BASE_URLS } from "../../utils/fetchUtils";
import { useForm } from "../../utils/useForm";
import { Input, InputPassword } from "../../components";

const Login = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userData, newDataChange] = useForm({
    email: "",
    password: "",
  });

  const login = async () => {
    setLoading(true);
    const res = await Request(
      BASE_URLS.GENERAL + "/staging/auth/login",
      {
        email: email,
        password: password,
      },
      "POST"
    );
    if (res.status) {
      const { token: jwt, userAttributes } = res;
      localStorage.setItem("jwt", jwt);
      localStorage.setItem("email", userAttributes.email);
      localStorage.setItem(
        "onboardingCompleted",
        userAttributes.onboardingCompleted
      );
      localStorage.setItem("sUserData", JSON.stringify(userAttributes));
      localStorage.setItem("zohoContactId", userAttributes.zohoContactId);
      const tokenJwt = localStorage.getItem("jwt");
      const resContact = await Request(
        BASE_URLS.GENERAL + "/staging/back-office/user/contact",
        {},
        "GET",
        false,
        false,
        {
          Authorization: `Bearer ${tokenJwt}`,
        }
      );
      if (resContact.status) {
        localStorage.setItem("name", resContact.contact.Full_Name);
        localStorage.setItem("layout", resContact.contact.Layout);
        localStorage.setItem(
          "avalMismoRL",
          resContact.contact.Representante_legal_mismo_que_obligado_solidario
        );
        localStorage.setItem(
          "tipoServicio",
          resContact.contact?.Solicitudes[0]?.Tipo_de_servicio
        );
        localStorage.setItem(
          "Solicitudes",
          JSON.stringify(resContact.contact?.Solicitudes)
        );
      }
      // if (localStorage.getItem("zohoContactId") === "undefined")
      //   setSection("Steps"); aqui se envia para el step en caso no haya completado el proceso
      window.location.href = "/dashboard";
    } else if (res.message === "Incorrect username or password.") {
      Swal.fire({
        icon: "error",
        title: "Usuario o contraseña incorrectos.",
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "¡Oh no, algo salió mal! Inténtalo de nuevo",
        showConfirmButton: false,
        timer: 2000,
      });
    }
    setLoading(false);
  };

  const { email, password } = userData;

  return (
    <>
      <div className="general-container">
        <div className="footer-container">
          <img
            className="selloAnimado"
            src="/SelloAnimado.svg"
            alt="animation"
          ></img>
        </div>
      </div>
      <div className="w-screen h-screen flex justify-center items-center">
        <div className="grid grid-cols-12">
          <div className="col-span-12 lg:col-span-6 lg:col-start-4 border border-black rounded-lg py-4 pb-10">
            <div className="grid grid-cols-12 mx-auto lg:px-10 max-sm:p-2">
              <div className="col-span-12">
                <div className="header-container">
                  <div className="image-container">
                    <img
                      className="iconView"
                      src="/images/finerik-logo.png"
                      alt="loginIcon"
                    ></img>
                  </div>
                </div>
              </div>
              <div className="col-span-12 text-center">
                <h2 className="title-container max-sm:text-xl max-sm:mb-2">
                  Inicia sesión
                </h2>
              </div>
              <div className="col-span-12">
                <Input
                  placeholder="Correo electrónico"
                  name="email"
                  value={email}
                  onChange={newDataChange}
                  icon={<AtSign></AtSign>}
                />
              </div>
              <div className="col-span-12">
                <InputPassword
                  placeholder="Contraseña"
                  name="password"
                  type="password"
                  value={password}
                  onChange={newDataChange}
                  icon={<KeyRound></KeyRound>}
                />
              </div>
              <div className="col-span-12 text-right mb-4">
                <div
                  className="forgotPass-text"
                  onClick={() => navigate("/auth/forgot")}
                >
                  ¿Olvidaste tu contraseña?
                </div>
              </div>
              <div className="col-span-12">
                <CustomButton
                  text="Iniciar sesión"
                  onClick={login}
                  className="!text-center !px-0 !w-full"
                />
              </div>
              <div className="col-span-12 mt-3">
                <div className="divisor-container">
                  <div className="line"></div>
                  <div>o</div>
                  <div className="line"></div>
                </div>
                <div className="otherOptions-container">
                  <CustomButton2
                    icon={<Mail></Mail>}
                    text="Realiza tu pre-aprobación"
                    onClick={() => {
                      navigate("/on-boarding");
                    }}
                  ></CustomButton2>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="btnAction-container">
          <div
            className="btn"
            onClick={() => {
              login();
            }}
          >
            <img
              className="btnNext"
              src="/images/nextBtn.svg"
              alt="altBtn"
            ></img>
          </div>
        </div> */}
      </div>
      <CustomLoader isLoading={loading} />
    </>
  );
};

export default Login;
